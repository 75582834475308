<template>
  <div class="mb-100">
    <div class="divider-full"></div>
    <div class="container-standar">
      <div class="container-standar mb-10 pad-20">
        <h2 class="text-primary font-weight-7 mb-30 font-30 text-center">DOKUMENTASI MANUAL</h2>
        <div class="shadow card pad-20 text-center ">
            <h2 class="font-26 font-weight-5">
            Tabel list dokumen manual - Daftar Dokumen SAIK +
          </h2>
          <br />
          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col">No</th>
                <th scope="col">Keterangan</th>
                <th scope="col">Tanggal Update</th>
                <th scope="col">Download</th>
              </tr>
            </thead>
            <tbody>
              <template>
                <tr>
                  <th scope="row">1</th>
                  <td>
                    <span class="text-primary font-weight-6 cursor-pointer"
                      >Aplikasi offline-saikplus</span
                    >
                  </td>
                  <td>
                    <span class="font-weight-4">26 Agustus 2021</span>
                  </td>
                  <td>
                    <a
                      href="https://drive.google.com/file/d/169SB187wzOZjDOF1ohfjpFU8DZaXwNH9/view?usp=sharing"
                      ><b-button variant="success"
                        ><b-icon icon="download" variant="white"></b-icon>
                        Download</b-button
                      ></a
                    >
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
          <br />
          <p>
            Untuk menggunakan Applikasi Offline SAIK+, Download file diatas.
            Setelah berhasil di download,
            kemudian 'double click' pada file saikplus-offline Setup 1.6.0.exe
            untuk install aplikasi. <br />
            Cara penggunaan Aplikasi Offline SAIK+ ada di Buku panduan
            berikut:
            <a
              href="https://drive.google.com/file/d/1ZgRSbZTIzvC9FFKXDparl6kLHYaFaLPH/view?usp=sharing"
              >Buku Panduan</a
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      
    };
  },
  mounted() {
    this.$store.commit("SET_BG", false);
  }
};
</script>